import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { env, isMobile as checkMobile } from "../../app.constant"
import AIOBEFRPdf from "../../assets/pdf/precontractual-AIO-BE_FR.pdf";
import AIOBENLPdf from "../../assets/pdf/precontractual-AIO-BE_NL.pdf";
import AIONLNLPdf from "../../assets/pdf/precontractual-AIO-NL_NL.pdf";
import AIOFIFIPdf from "../../assets/pdf/precontractual-AIO-FI_FI.pdf";
import downloadIcon from '../../assets/images/download_icon.png';
import axios from "axios";
import TermsAndConditionsComponent from "../privacy-policy/terms-and-conditions.component";
import InsuranceProductInformationDocumentComponent from "../privacy-policy/insurance-product-information-document.component";
import OtherImportantInformationComponent from "../privacy-policy/other-important-information.component";
import debounce from "lodash.debounce";
import downArrow from '../../assets/images/down-arrow.svg'
import { Trans, I18nextContext } from "gatsby-plugin-react-i18next";

export const PrecontractualPopup = ({
  show = false, target, close = () => { }, accept = () => { },
}) => {
  const [isDisable, setDisable] = useState(true);
  const [isMobile, setIsMobile] = useState(false)
  const { language } = useContext(I18nextContext)

  function fetchFile() {
    let getUrl
    switch (env.GATSBY_COUNTRY) {
      case "BE":
        if(language === "be_fr"){
          getUrl = AIOBEFRPdf
        } else {
          getUrl = AIOBENLPdf
        }
        break;
      case "NL":
        getUrl = AIONLNLPdf
        break;
      case "FI":
        getUrl = AIOFIFIPdf
        break;

      default:
        break;
    }
    axios({
      url: getUrl,
      method: "GET",
      headers: { "Content-Type": "application/pdf" },
      responseType: "blob",
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", getUrl.substring(getUrl.lastIndexOf('/') + 1))
      document.body.appendChild(link)
      link.click()
    })
  }

  const scroll = useMemo(() => debounce(e => {
    const cal = Math.round(e.target.scrollHeight - e.target.scrollTop);
    const clientHeight = e.target.clientHeight
    console.log(`cal - ${cal}`);
    console.log(`clientHeight - ${clientHeight}`);
    const bottom = ((clientHeight - 5) < cal) && (cal < (clientHeight + 5))
    setDisable(!bottom);
  }, 50), [])

  function scrollToBottom() {
    document.querySelectorAll('[id=_dialog-content]').forEach(function(el) {
      el.scrollTo({ top: el.scrollHeight, behavior: "smooth" })
    });
  }

  useEffect(() => {
    setIsMobile(checkMobile())
  }, []);

  return (
    <DialogComponent
      className="precontractual-modal"
      visible={show}
      showCloseIcon
      isModal
      close={() => {
        document
          .getElementById("_dialog-content")
          .removeEventListener("scroll", scroll)
        close()
      }}
      header={() => (
        <>
          <span className="precontractual-save-pdf" onClick={fetchFile}>
            <img src={downloadIcon} alt="" />
            <a><Trans>precontractual.savepdf</Trans></a>
          </span>
        </>
      )}
      footerTemplate={() => (
        <div className="precontractual-footer">
          <button className={`scroll-btm right-${isMobile ? 95 : 80}`} onClick={scrollToBottom}>
            <img src={downArrow} width={15} height={15} />
          </button>
          {/* <p className="declare-text">
            <Trans>precontractual.confirmation</Trans>
          </p> */}
          {/* <button
            type="button"
            className={isMobile?"btn btn-block":"btn btn-center"}
            disabled={isDisable}
            id="getPlanProductPageBtn"
            onClick={() => {
              if (!isDisable) {
                document
                  .getElementById("_dialog-content")
                  .removeEventListener("scroll", scroll)
                close()
                accept()
              }
            }}
          >
            <Trans>precontractual.accept</Trans>
          </button> */}
        </div>
      )}
      open={() => {
        const el = document.getElementById("_dialog-content")
        setTimeout(() => {
          el.scrollTo({ top: 0, behavior: "smooth" })
        }, 300)

        el.addEventListener("scroll", scroll)
      }}
    >
      <div className="precontractual-dialog-content">
        {target==="tnc" && <div>
          <h1><Trans>precontractual.tnc</Trans> <Trans>detail.monthly-installment</Trans></h1>
          <TermsAndConditionsComponent isMonthly={true} />
        </div>}
        {target==="tnc" && <div>
          <h1><Trans>precontractual.tnc</Trans> <Trans>detail.one-off-pay</Trans></h1>
          <TermsAndConditionsComponent isMonthly={false} />
        </div>}
        {target==="ipid" && <div>
          <h1><Trans>precontractual.ipid</Trans></h1>
          <InsuranceProductInformationDocumentComponent />
        </div>}
        {target==="oii" && <div>
          <h1><Trans>precontractual.otherinfo</Trans></h1>
          <OtherImportantInformationComponent />
        </div>}
      </div>
    </DialogComponent>
  )
};
