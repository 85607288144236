import React, {useRef, useState, useContext} from 'react';
import commonQuestionImg from '../../assets/images/common-question.png';
import {Trans, useI18next, I18nextContext} from "gatsby-plugin-react-i18next"
import * as constants from "../../app.constant";
import { env } from '../../app.constant';
import { PrecontractualPopup } from '../product/PrecontractualPopup';

const CommonQuestionComponent = (props) => {
  const { navigate } = useI18next()
  const { language } = useContext(I18nextContext)
    const [getId, setId] = useState("")
    const [showPrecontract, setPrecontract] = useState(false)
    const [showDisplayPopUp, setDisplayPopUp] = useState("tnc")

    const handleTncClick = (param) => {
        setDisplayPopUp(param)
        setPrecontract(true)
    }
    
    function toggleQue (id) {
        document.getElementById(id).classList.toggle('active');
        if(getId===""){
            setId(id)
        }else{
            setId("")
        }
    }

  function  goToFaqPage() {
    navigate(constants.ROUTES.FAQ)
  }

    return (
      <>
        <div className={`${!props.isMobile?"pt-pb-40":""}`}>
          <div>
            <PrecontractualPopup
              target={showDisplayPopUp}
              show={showPrecontract}
              close={() => setPrecontract(false)}
            />
            <div className={`${props.isMobile?"container display-18":"desktop-container-responsive display-32"}`}>
              <span className={`samsung-sharp-sans-bold`}>
                <Trans>landing.component.common.questions</Trans>
              </span>
              <div className={`common-question ${!props.isMobile?"desktop pt-pb-40":"pt-24"}`}>
                <img src={commonQuestionImg} width={`${!props.isMobile?"550px":"100%"}`} className="stretch"/>
                <ul>
                  <li id="que1">
                    <h3 className={getId!==""&&getId==="que1"?"que":"que border-bottom-line"} onClick={() => toggleQue('que1')}><Trans>faq.tab1.title</Trans></h3>
                    <div className="ans">
                      <p><Trans>faq.tab1.description1</Trans></p>
                      <p><Trans>faq.tab1.s1</Trans><br/><Trans>faq.tab1.s2</Trans></p>
                      <p><Trans>faq.tab1.description2</Trans></p>
                      <p><Trans components={{
                        1: (
                          <a
                            href="javascript:void(0)"
                            tabIndex="-1"
                            onClick={() => handleTncClick("tnc")}
                          />
                        )
                      }}>faq.tab1.description3</Trans></p>
                    </div>
                  </li>
                  <li id="que2">
                    <h3 className={getId!==""&&getId==="que2"?"que":"que border-bottom-line"} onClick={() => toggleQue('que2')}><Trans>faq.tab2.title</Trans></h3>
                    <div className="ans">
                      <p><Trans>faq.tab2.description1</Trans></p>
                      <p><Trans>faq.tab2.s1</Trans><br/><Trans>faq.tab2.s2</Trans></p>
                      <p><Trans components={{
                        1: (
                          <a
                            href="javascript:void(0)"
                            tabIndex="-1"
                            onClick={() => handleTncClick("tnc")}
                          />
                        )
                      }}>faq.tab2.description2</Trans></p>
                    </div>
                  </li>
                  <li id="que3">
                    <h3 className={getId!==""&&getId==="que3"?"que":"que border-bottom-line"} onClick={() => toggleQue('que3')}><Trans>faq.tab3.title</Trans></h3>
                    <div className="ans">
                      <p><Trans>faq.tab3.description</Trans></p>
                    </div>
                  </li>
                  <li id="que4">
                    <h3 className={getId!==""&&getId==="que4"?"que":"que border-bottom-line"} onClick={() => toggleQue('que4')}><Trans>faq.tab4.title</Trans></h3>
                    <div className="ans">
                      <p><Trans>faq.tab4.description1</Trans>
                        {env.GATSBY_COUNTRY === 'BE' &&
                        <a href="https://be.careplus.co" target="_blank" rel="noreferrer" style={{color:"blue"}}>
                          https://be.careplus.co
                        </a>
                        }
                        {env.GATSBY_COUNTRY === 'NL' &&
                        <a href="https://nl.careplus.co" target="_blank" rel="noreferrer" style={{color:"blue"}}>
                          https://nl.careplus.co
                        </a>
                        }
                        {env.GATSBY_COUNTRY === 'FI' &&
                        <a href="https://fi.careplus.co" target="_blank" rel="noreferrer" style={{color:"blue"}}>
                          https://fi.careplus.co
                        </a>
                        }
                      </p>
                      <p><Trans>faq.tab4.description2</Trans></p>
                      <p><Trans>faq.tab4.description3</Trans>
                        {language === "en" && env.GATSBY_COUNTRY === 'BE' &&
                        "+3278079260"
                        }
                        {language === "en" && env.GATSBY_COUNTRY === 'NL' &&
                        "+31858881836"
                        }
                        {language === "en" && env.GATSBY_COUNTRY === 'FI' &&
                        "+358753254781"
                        }
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`text-center ${props.isMobile?"pt-24 pb-32":""}`}>
            <button
              type="button"
              onClick={goToFaqPage}
              className="btn-white"
            >
              <Trans>landing.component.common.see.more</Trans>
            </button>
          </div>
        </div>
      </>

    )
}
export default CommonQuestionComponent;