import React, {useContext, useEffect, useRef, useState} from 'react';
import landing_banner from '../../assets/images/landing_banner.png';
import landing_banner_desktop from '../../assets/images/landing_banner_desktop.png';
import boltech_logo from '../../assets/images/landing_bolttech_logo.svg';
import * as constants from "../../app.constant";
import { env } from "../../app.constant";
import {I18nextContext, Trans, useI18next} from "gatsby-plugin-react-i18next"
import TrendingProductsComponent from "./trendingProducts.component";
import landing_1 from "../../assets/images/landing_1.svg";
import landing_2 from "../../assets/images/landing_2.svg";
import landing_3 from "../../assets/images/landing_3.svg";
import landing_4 from "../../assets/images/landing_4.svg";
import deviceStore from "../../store/deviceStore";
import loaderStore from "../../store/loaderStore";
import * as Utils from "../../app.utils";
import CommonQuestionComponent from "./commonQuestion";
import { PrecontractualPopup } from '../product/PrecontractualPopup';
import { PrecontractualTouPopup } from './PrecontractualPopup';
import ContactUsComponent from "./contactUs";

const LandingComponent = ({ isMobile, isTablet}) => {
  const { navigate } = useI18next()
  const { language } = useContext(I18nextContext)
  const eleRef = useRef(null)
  const [ trendingProduct, setTrendingProduct ] = useState([])
  const [ bannerImgDesktop, setBannerImgDesktop ] = useState("")
  const [ bannerImgMobile, setBannerImgMobile ] = useState("")
  const [showPrecontract, setPrecontract] = useState(false)
  const [showDisplayPopUp, setDisplayPopUp] = useState("")

  const [showPrecontractTou, setPrecontractTou] = useState(false)
  const [showDisplayPopUpTou, setDisplayPopUpTou] = useState("")
  const [isInView, setIsInView] = useState(false)
  

  async function getPlanShouldUse(deviceUniqueId, forceToken = false) {
    try {
      const isMobileSelected = deviceStore.deviceType === constants.DEVICE_TYPE.MOBILE.toString()
      const validKeyName = (isMobileSelected? "imeiValid" : "serialValid");
      const token = await Utils.getOrderToken(forceToken);
      const resp = await (
        await fetch(
          `${constants.env.GET_SAMSUNG_PRODUCT_LIST_API_URL}?device_unique_id=${deviceUniqueId
          }&device_id_type=${(isMobileSelected?"imei":"serial-number")}`,
          {
            headers: {
              "bolt-country-code": constants.env.GATSBY_COUNTRY_CODE,
              "bolt-language-code": "EN",
              "bolt-tenant-id": constants.env.GATSBY_TENANT_ID,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
      ).json();
      if (resp?.message === "Forbidden") {
        throw new Error(resp.message)
      }

      let errorMsg = null;
      let errorKey = null;
      if(isMobileSelected){
        errorKey = "imeiError";
      }else{
        errorKey = "serialError";
      }
      switch (resp._status.code) {
        // lets ensure SUCCESS code mean passing validation
        case constants.STATUS_CODE.SUCCESS:
          break;
        // else all status code that just show `...IMEI is not eligible for protection...` message
        case constants.STATUS_CODE.INVALID_REQUEST_PARAMETERS:
        case constants.STATUS_CODE.UNSUPPORTED_DEVICE_MODEL:
        case constants.STATUS_CODE.NOT_EXISTS_SAMSUNG_DEVICE_IMEI:
        default:
          errorMsg = "deviceInfo.error.label7"
          break
      }
      if(errorMsg) {
        localStorage.setItem("validKeyName", validKeyName)
        localStorage.setItem("errorKey", errorKey)
        localStorage.setItem("errorMsg", errorMsg)  
      }
      // optional filter
      let filteredProducts = resp.products?.filter(
        ({ insurance_company_type_code }) =>
          insurance_company_type_code === "Bolttech"
      )
      return {
        result: !errorMsg,
        plans: filteredProducts
      }
    } catch (error) {
      if (error.message === "Forbidden") {
        // retries when token is not work for api call
        return this.getPlanShouldUse(
          deviceUniqueId,
          true
        );
      }
    }
    return false;
  }

  async function deviceVerificationApiCall(deviceUniqueId, planId, forceToken = false) {
    try {
      const isMobileSelected = deviceStore.deviceType === constants.DEVICE_TYPE.MOBILE.toString()
      const validKeyName = (isMobileSelected? "imeiValid" : "serialValid");
      const token = await Utils.getOrderToken(forceToken)
      if (token === null) throw new Error("No token found.")

      const resp = await (
        await fetch(constants.env.DEVICE_VERIFICATION_API_URL, {
          method: "POST",
          headers: {
            "bolt-country-code": constants.env.GATSBY_COUNTRY_CODE,
            "bolt-language-code": "EN",
            "bolt-tenant-id": constants.env.GATSBY_TENANT_ID,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            device_unique_id: deviceUniqueId,
            partner_code: constants.env.GATSBY_PARTNER_CODE,
            device_id_type: (isMobileSelected?"imei":"serial-number"),
            plan_id: planId,
          }),
        })
      ).json()
      if (resp?.message === "Forbidden") {
        throw new Error(resp.message)
      }

      // deviceStore.deviceSerialNumber = resp2.device_serial_number
      let errorMsg = null
      let errorKey = null
      let voucherError = false
      if(isMobileSelected){
        errorKey = "imeiError"
      }else{
        errorKey = "serialError"
      }
      switch (resp._status.code) {
        // lets ensure SUCCESS code mean passing validation
        case constants.STATUS_CODE.SUCCESS:
          // old code, which purpose is to update google analytic, ignore first
          //   updateGuestDetailsInDFP(null, null, deviceImei, dfpStore.guestId)
          break

        // if is validation return ALREADY_REGISTERED_DEVICE, give special message
        case constants.STATUS_CODE.ALREADY_REGISTERED_DEVICE:
          errorMsg = "deviceInfo.error.label2"
          break
        // if is validation return UNSUPPORTED_EULA_ACKNOWLEGDE_DATE, give special message
        case constants.STATUS_CODE.UNSUPPORTED_EULA_ACKNOWLEGDE_DATE:
          errorMsg = "deviceInfo.error.label6"
          break

        // else all status code that just show `...IMEI is not eligible for protection...` message
        case constants.STATUS_CODE.INVALID_REQUEST_PARAMETERS:
        case constants.STATUS_CODE.UNSUPPORTED_DEVICE_MODEL:
        case constants.STATUS_CODE.NOT_EXISTS_SAMSUNG_DEVICE_IMEI:
        default:
          errorMsg = "deviceInfo.error.label7"
          break
      }
      if(errorMsg) {
        localStorage.setItem("validKeyName", validKeyName)
        localStorage.setItem("errorKey", errorKey)
        localStorage.setItem("errorMsg", errorMsg)
      }
      const {
        result: { device_model_full_id: device_model, device_model_name, device_activation_date },
      } = resp
      return {
        result: !errorMsg && !voucherError,
        device_model,
        device_model_name,
        device_activation_date
      }
    } catch (error) {
      if (error.message === "Forbidden") {
        // retries when token is not work for api call
        return this.deviceVerificationApiCall(
          deviceUniqueId,
          planId,
          true
        )
      }
    }
    return false
  }
  
  function redirectPage() {
    if(deviceStore &&
      deviceStore.deviceType && 
      deviceStore.deviceImei
    ) {
      goPlanSelectionPage()
    } else {
      goToDeviceEligibilityPage()
    }
  }

  async function goPlanSelectionPage() {
    loaderStore.showLoader(true)
    const startTime = new Date().getTime()
    let deviceUniqueId = deviceStore.deviceImei
    const {result: planResult, plans} = await getPlanShouldUse(deviceUniqueId, false)
    if(!planResult) {
      loaderStore.showLoader(false)
      return false;
    }
    let planId = (plans && plans.length > 0) ? plans[0].product_id : null;
    const {
      result,
      device_model,
      device_model_name,
      device_activation_date
    } =  await deviceVerificationApiCall(deviceUniqueId, planId)
    
    if (!result) {
      loaderStore.showLoader(false)
      navigate(constants.ROUTES.DEVICE_ELIGIBILITY)
    } else {
      deviceStore.deviceModel = device_model
      deviceStore.deviceModelDesc = device_model_name
      deviceStore.deviceActivationDate = device_activation_date
      localStorage.setItem("startTime", startTime.toString())
      loaderStore.showLoader(false)
      navigate(constants.ROUTES.PRODUCT)
    }
  }
  
  function goToDeviceEligibilityPage() {
    navigate(constants.ROUTES.DEVICE_ELIGIBILITY)
  }

  function  goToFaqPage() {
    navigate(constants.ROUTES.FAQ)
  }

  async function getTrendingProducts() {
    return await (
      await fetch(`${env.GET_TRENDING_PRODUCT_BY_PARTNER_ID}?partnerID=${env.GATSBY_PARTNER_CODE}`, {
        method: "GET",
        headers: {
          "x-api-key": env.GET_PRODUCT_LIST_X_API_KEY,
          "Content-Type": "application/json",
        },
      })
    ).json()
  }

  function bannerTxtClass() {
    if (language === "be_fr") {
      return "banner-txt-fr"
    } else if (language === "fi_fi") {
      return "banner-txt-fi"
    } else {
      return ""
    }
  }

  function handleClickTou (param) {
    setDisplayPopUpTou(param)
    setPrecontractTou(true)
  }

  function handleClick (param) {
    setDisplayPopUp(param)
    setPrecontract(true)
  }

  function checkInView() {
    const rect = eleRef.current.getBoundingClientRect()
    setIsInView(
      rect.top < window.innerHeight && rect.bottom >= 0
    )
  };

  useEffect(() => {
    checkInView()
    getTrendingProducts().then(res => {
      if(res.status === 'OK') {
        let sortedTrendingProducts = res.obj.sort( (p1, p2) => (p1.seq > p2.seq) ? 1 : (p1.seq < p2.seq) ? -1 : 0)
        setTrendingProduct(sortedTrendingProducts)
        console.log('getTrendingProducts', res.obj)
      } else {
        setTrendingProduct([])
        console.log('getTrendingProducts Api ERROR')
      }
    })

    setBannerImgDesktop(env.LANDING_BANNER_DESKTOP)
    setBannerImgMobile(env.LANDING_BANNER_MOBILE)
  }, [])

  useEffect(() => {
    document.addEventListener("scroll", checkInView)
    return () => {
      document.removeEventListener("scroll", checkInView);
    }
  }, [])

    return (
      <>
        <PrecontractualPopup
          target={showDisplayPopUp}
          show={showPrecontract}
          close={() => setPrecontract(false)}
        />

        <PrecontractualTouPopup
          target={showDisplayPopUpTou}
          show={showPrecontractTou}
          close={() => setPrecontractTou(false)}
        />
        <div className={`content-wrapper ${isMobile?"pb-32":""}`}>
          {isMobile && (
            <div className="banner">
              <img src={bannerImgMobile} alt="" width="100%" />
              <p className={`banner-txt`}>
                <span className="banner-txt-title">
                  <Trans>landing.banner.label</Trans>
                </span>
              </p>
            </div>
          )}
          {!isMobile && (
            <div className="banner display-flex">
              <img className={"object-fit-cover"} src={bannerImgDesktop} alt="" width="100%" height="520px"/>
              <div className={`banner-txt ${bannerTxtClass()}`}>
                <p className={`desktop-banner`}>
                  <span className="banner-txt-title desktop">
                    <Trans>landing.banner.label</Trans>
                  </span>
                </p>
              </div>
            </div>
          )}
          <div className={`${!isMobile?"pt-pb-40 desktop-container-responsive bg-grey":"pt-32 container-responsive bg-grey"}`}>
            <div className={`mb-25 ${isMobile?"display-20":"display-32"}`}>
              <span className="samsung-sharp-sans-bold"><Trans>landing.title1</Trans></span>
            </div>
            <div className={!isMobile?"coverage-container-desktop":""}>
              <div className={!isMobile?"coverage-container-desktop-column":""}>
                <div className="coverage-container-mobile">
                  <img src={landing_1} alt="" width="25" />
                  <h3 className={`ml-25 ${isMobile?"display-16":"display-18"}`}>
                    <Trans>landing.content.label1</Trans>
                  </h3>
                </div>
              </div>
              <div className={!isMobile?"coverage-container-desktop-column":""}>
                <div className="coverage-container-mobile">
                  <img src={landing_2} alt="" width="30" />
                  <h3 className={`ml-20 ${isMobile?"display-16":"display-18"}`}>
                    <Trans>landing.content.label3</Trans>
                  </h3>
                </div>
              </div>
              <div className={!isMobile?"coverage-container-desktop-column":""}>
                <div className="coverage-container-mobile mb-0">
                  <img src={landing_3} alt="" width="30" />
                  <h3 className={`ml-20 ${isMobile?"display-16":"display-18"}`}>
                    <Trans>landing.content.label2</Trans>
                  </h3>
                </div>
              </div>
              {env.GATSBY_COUNTRY === 'FI' &&
              <div className={!isMobile?"coverage-container-desktop-column":""}>
                <div className="coverage-container-mobile pt-24">
                  <img src={landing_4} alt="" width="30" />
                  <h3 className={`ml-20 ${isMobile?"display-16":"display-18"}`}>
                    <Trans>landing.content.label4</Trans>
                  </h3>
                </div>
              </div>
              }
            </div>
            <div className="text-center pt-32" ref={eleRef}>
              <button
                type="button"
                onClick={redirectPage}
                className={`btn mb-16 ${isMobile?"btn-block":""}`}
              >
                <Trans>landing.protect.my.device</Trans>
              </button>
            </div>
            
            <div className={`coverage-note display-14 ${!isMobile?"pb-20":""}`}>
              <p style={{"marginBottom":"25px"}}>
                <Trans>landing.content.label9</Trans>
              </p>
            </div>
            <div className={`display-12 ${!isMobile?"coverage-note":""}`}>
              <p>
                <Trans components={{
                  1: <a className="underline_font" onClick={() => handleClick("tnc")} />,
                  2: <a className="underline_font" onClick={() => handleClick("ipid")} />,
                  3: <a className="underline_font" onClick={() => handleClick("oii")} />,
                  4: <a className="underline_font" onClick={() => handleClickTou("tou")} />,
                }}>
                  landing.content.label10
                </Trans>
              </p>
            </div>
            <div className={`display-12 ${!isMobile?"coverage-note":""}`}>
              <p className="mb-0">
                <Trans>landing.content.label11</Trans>
              </p>
            </div>
          </div>
        </div>
        <CommonQuestionComponent isMobile={isMobile} />
        <div className={`trending-background ${isMobile?"pt-32 pb-32":"pt-pb-40"}`}>
          <div className={`${!isMobile ? "desktop-container-responsive" : "container-responsive"}`}>
            {trendingProduct.length > 0 && (
              <TrendingProductsComponent isMobile={isMobile} isTablet={isTablet} trendingProducts={trendingProduct}/>
            )}
          </div>
        </div>
        <ContactUsComponent isMobile={isMobile}/>
        <div className={`${!isMobile ? "desktop-container-responsive" : "container-responsive"}`}>
          {/*<VideoComponent isMobile={isMobile} />*/}
          {/*<PlanCoverageComponent isMobile={isMobile} isTablet={isTablet}/>*/}
          {/*<NotCoveredComponent isMobile={isMobile} isTablet={isTablet}/>*/}
          {/*<hr />*/}
          {/*<div className="pt-20 mb-25 need-more-info">
            <h3 className={isMobile?"samsung-sharp-sans-bold":"samsung-sharp-sans-bold display-32"}>
              <Trans>landing.content.label7</Trans>
            </h3>
            <div>
              <a
                  className={isMobile?"learn-more-btn display-14":"learn-more-btn display-18"}
                  id="learnMoreBtn"
                  onClick={goToFaqPage}
              >
                <Trans>landing.content.label8</Trans>
              </a>
            </div>
          </div>*/}
          {/*<hr />*/}
          <div className={`text-left boltech-intro-container`}>
            <div>
              <h4 className={isMobile?"display-14":"display-16"}>
                <img src={boltech_logo} alt="" className="boltech-logo" />{" "}
                <Trans>landing.content.label5</Trans>
              </h4>
            </div>
            <div>
              <p className={isMobile?"text-grey display-12":"text-grey display-14"}>
                <Trans>landing.content.label6</Trans>
              </p>
            </div>
            <div>
              <p className={isMobile?"text-grey display-12":"text-grey display-14"}>
                <Trans components={{
                  1: <a className="underline_font" onClick={() => handleClick("tnc")} />,
                  2: <a className="underline_font" onClick={() => handleClickTou("tou")} />,
                }}>
                  landing.content.label12
                </Trans>
              </p>
            </div>
          </div>
        </div>

        {isMobile && !isInView && <div className="landing-footer">
          <div className={`${!isMobile ? "desktop-container-responsive" : "container-responsive"}`}>
            <div>
              <button type="button" onClick={redirectPage} className="btn btn-block" id="getProtectionTopBtn"><Trans>landing.protect.my.device</Trans></button>
            </div>
          </div>
        </div>}
      </>
    )
}

export default LandingComponent;