import React from 'react';
import contact_us_mobile from "../../assets/images/contact-us-mobile.png";
import contact_us_desktop from "../../assets/images/contact-us-desktop.png";
import {Trans} from "gatsby-plugin-react-i18next";
import contact_us_1 from "../../assets/images/contact_us_1.svg";
import * as constants from "../../app.constant";
import contact_us_2 from "../../assets/images/contact_us_2.svg";
import contact_us_3 from "../../assets/images/contact_us_3.svg";
import contact_us_4 from "../../assets/images/contact_us_4.svg";

const ContactUsComponent = (props) => {
  
    return (
      <>
        <div className="contact-us-container">
          {props.isMobile && <img src={contact_us_mobile} width="100%" height="330px"/>}
          {!props.isMobile && <img src={contact_us_desktop} width="100%"/>}
          {props.isMobile &&
          <span className="contact-us-content">
            <h3 className="pb-24 samsung-sharp-sans-bold">
              <Trans>landing.component.contact.us.title</Trans>
            </h3>
            <div className="contact-us-item">
              <img src={contact_us_1} alt="" width="25" />
              <p className="ml-20">{constants.env.CONTACT_US_EMAIL}</p>
            </div>
            <div className="contact-us-item">
              <img src={contact_us_2} alt="" width="25" />
              <p className="ml-20">{constants.env.CONTACT_US_PHONE}</p>
            </div>
            <div className="contact-us-item">
              <img src={contact_us_3} alt="" width="25" />
              <p className="ml-20">{constants.env.CONTACT_US_WEBSITE}</p>
            </div>
            <div className="contact-us-item">
              <img src={contact_us_4} alt="" width="25" />
              <span className="ml-20">
                <Trans>landing.component.contact.us.call.center.hours</Trans>
              </span>
            </div>
            <div className="ml-45 contact-us-call-center-hour">
              <p><Trans>landing.component.contact.us.content1</Trans>- 8:00 - 19:00</p>
              <p><Trans>landing.component.contact.us.content2</Trans>- 10:00 - 15:00</p>
              <p><Trans>landing.component.contact.us.content3</Trans></p>
            </div>
          </span>
          }
          {!props.isMobile &&
          <span className="contact-us-content desktop">
            <div className="contact-us-container-desktop">
              <div className="contact-us-container-desktop-column title">
                <h1 className="pb-20 samsung-sharp-sans-bold display-32">
                  <Trans>landing.component.contact.us.title</Trans>
                </h1>
              </div>
              <div className="contact-us-container-desktop-column">
                <div className="contact-us-item desktop">
                  <img src={contact_us_1} alt="" width="25" />
                  <p className="ml-20">{constants.env.CONTACT_US_EMAIL}</p>
                </div>
                <div className="contact-us-item desktop">
                  <img src={contact_us_2} alt="" width="25" />
                  <p className="ml-20">{constants.env.CONTACT_US_PHONE}</p>
                </div>
              </div>
              <div className="contact-us-container-desktop-column">
                <div className="contact-us-item desktop">
                  <img src={contact_us_3} alt="" width="25" />
                  <p className="ml-20">{constants.env.CONTACT_US_WEBSITE}</p>
                </div>
                <div className="contact-us-item desktop">
                  <img src={contact_us_4} alt="" width="25" />
                  <span className="ml-20">
                  <Trans>landing.component.contact.us.call.center.hours</Trans>
                </span>
                </div>
                <div className="ml-45 contact-us-call-center-hour desktop">
                  <p><Trans>landing.component.contact.us.content1</Trans>- 8:00 - 19:00</p>
                  <p><Trans>landing.component.contact.us.content2</Trans>- 10:00 - 15:00</p>
                  <p><Trans>landing.component.contact.us.content3</Trans></p>
                </div>
              </div>
            </div>
          </span>
          }
          
        </div>
      </>
      
    )
}
export default ContactUsComponent;