import React, { useEffect } from "react"
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import * as constants from "../../app.constant";

const TrendingProductsComponent = (props) => {
  const { navigate } = useI18next()

  function goToDeviceEligibilityPage() {
    navigate(constants.ROUTES.DEVICE_ELIGIBILITY)
  }

  useEffect(() => {
    console.log('props', props)
  }, [])

  return (
    <div className="plan-coverage">
      <h3 className={`samsung-sharp-sans-bold ${props.isMobile?"display-18":"display-32"}`}>
        <Trans>landing.component.trending.products.title</Trans>
      </h3>
      <span className={`${props.isMobile ? "display-12" : "display-14"}`}>
        <Trans>landing.component.trending.products.label1</Trans>
      </span>
      {(props.isMobile || props.isTablet) && (
        <div className="trending-flex-container mobile pt-24" style={{overflow:"hidden"}}>
          {props.trendingProducts.map(( tp, i ) => (
            <div className="card-column trending-mobile" id={`slide${i+1}`} key={i}>
              <div className="card-content trending-mobile">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="icon">
                          <img src={tp.imageUrl} alt="" width="80"/>
                        </div>
                      </td>
                      <td>
                        <h4>{tp.modelName}</h4>
                        <span className="display-11"><Trans>landing.component.trending.products.label2</Trans></span>
                        <br />
                        <span className="display-16">
                          <strong>
                            {tp.monthlyPremium}
                            <Trans>landing.component.trending.products.label4</Trans>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <div>
                          <button
                            type="button"
                            onClick={goToDeviceEligibilityPage}
                            className="btn trending-products-btn"
                          >
                            <Trans>landing.component.trending.products.button</Trans>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      )}
      {!props.isMobile && !props.isTablet && (
        <>
          <div className="trending-flex-container desktop">
            {props.trendingProducts.map(( tp, i ) => (
              <div className="card-column trending-desktop" id={`slide${i+1}`} key={i}>
                <div className="card-content">
                  <div className="icon">
                    <img src={tp.imageUrl} alt="" />
                  </div>
                  <h3>{tp.modelName}</h3>
                  <p className="display-14"><Trans>landing.component.trending.products.label2</Trans></p>
                  <p className="display-14">
                    <strong>
                      {tp.monthlyPremium}
                      <Trans>landing.component.trending.products.label4</Trans>
                    </strong>
                  </p>
                  <button
                    type="button"
                    onClick={goToDeviceEligibilityPage}
                    className="btn trending-products-btn"
                  >
                    <Trans>landing.component.trending.products.button</Trans>
                  </button>
                </div>
              </div>
            ))}

          </div>
        </>
      )}
      <div className={`text-center display-14 ${props.isMobile?"pt-8":""}`}>
        <p>
          <Trans components={{
            1: <a className="underline_font" onClick={() => goToDeviceEligibilityPage()} />,
          }}>
            landing.component.trending.products.label3
          </Trans>
        </p>
      </div>
    </div>
  )
}
export default TrendingProductsComponent
