import React, {useEffect, useState} from "react";
import {graphql} from "gatsby";
import LandingComponent from '../components/landing/landing.component';
import Layout from '../components/shared/layout';
import {initFingerPrintSDK} from "../app.utils";
import LandingFooter from "../components/landing/footer";
import {isMobile as checkMobile, isTablet as checkTablet} from "../app.constant"

const IndexPage = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    document.title = 'Bolttech D2C';
    // localStorage.clear();
    // if (!isMobile()) {
    //   localStorage.setItem('isRouteFromPurchase', true);
    //   navigate(constants.ROUTES.QR_SCAN)
    // } else {
      initFingerPrintSDK(null, null, null);
      // getData();
    // }
    setIsMobile(checkMobile())
    setIsTablet(checkTablet())
  }, []);

  /* 
  const [productArrData, setProductArr] = useState({});
  const [deviceModel, setDeviceModel] = useState('');
  const [deviceRrp, setDeviceRrp] = useState('');

  const productList = useStaticQuery(graphql
    `query MyQuery {
      allProductList(filter: {productId: {in: ["SES_PH_D2C_1Y", "SES_PH_D2C_1M"]}}) {
          nodes {
              clientProductDescription
              clientProductName
              covered
              productId
              productCoverage
              productName
              tenure
              tenureType
              description
              plans {
                bandNo
                planId
                coverage
                currency
                devicePriceStartingRange
                devicePriceEndingRange
                productId
                totalRRPValueWithTax
                actualProductPrice
                discountedProductPrice
                fulfillmentSystemPrice
                paymentSystemPrice
              }
        }
      }
    }`
  )

  const getData = async () => {
    const fetchedProduct = JSON.parse(JSON.stringify(productList.allProductList.nodes));
    console.log("product ;iost", fetchedProduct);

    const userAgent = window.navigator.userAgent;
    if (!userAgent) {
      localStorage.setItem('isRouteFromPurchase', true);
      navigate(constants.ROUTES.UA_ERROR)
    }

    const basisCode = userAgent && getBasisCode();
    if (!basisCode && userAgent) {
      localStorage.setItem('isRouteFromPurchase', true);
      navigate(constants.ROUTES.UA_ERROR);
    }

    const deviceDetail = basisCode && await getDeviceDetail(basisCode);
    if (basisCode && !(deviceDetail && deviceDetail.deviceModel && deviceDetail.deviceRrp)) {
      localStorage.setItem('isRouteFromPurchase', true);
      navigate(constants.ROUTES.DEVICE_INFO_ERROR)
    }
    const productArr = deviceDetail && getProduct(deviceDetail.deviceRrp, fetchedProduct);
    productArr && productArr.sort((a, b) => a.tenure - b.tenure);   //to make sure monthly product comes first
    productArr && setProductArr(productArr);

    deviceDetail && deviceStore.updateDeviceInfo(deviceDetail.deviceModel, deviceDetail.deviceRrp)
    deviceDetail && setDeviceModel(deviceDetail.deviceModel);
    deviceDetail && setDeviceRrp(deviceDetail.deviceRrp);

    productArr && planStore.updateProductInfo(productArr[0]);
    productArr && planStore.saveProductArrToStore(productArr);
    if (deviceDetail && !JSON.parse(localStorage.getItem('deviceStore'))) {   //sometimes device store is not saving into localstorage hence manually storing
      const deviceObj = {
        deviceModel: deviceDetail.deviceModel,
        deviceRrp: deviceDetail.deviceRrp
      }
      localStorage.setItem('deviceStore', JSON.stringify(deviceObj))
    }
  };

  const getBasisCode = () => {
    const userAgent = window.navigator.userAgent;
    const startingIndex = userAgent.indexOf(constants.BASIS_CODE_INITIAL);
    const basisCode = startingIndex !== -1 ? userAgent.substring(startingIndex, startingIndex + 7) : null;
    return basisCode;
  }

  const getDeviceDetail = (basisCode) => {
    loaderStore.showLoader(true);
    return new Promise(resolve => {
      const url = `${process.env.GET_DEVICE_INFO_API_URL}${basisCode}`;
      const method_options = {
        headers: {
          "x-api-key": process.env.GET_DEVICE_INFO_X_API_KEY,
          "bolt-country-code": constants.HEADER_REQUEST.boltCountryCode,
          "bolt-tenant-id": constants.HEADER_REQUEST.boltTenantId,
          "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode
        }
      }
      fetch(url, method_options)
        .then(res => res.json())
        .then(response => {
          loaderStore.showLoader(false);
          if (response._status.code === '80001') {
            resolve({
              deviceRrp: response.result.device_rrp,
              deviceModel: response.result.device_model
            });
          } else {
            console.log("error in device info api");
            resolve(null);
          }
        })
        .catch(err => {
          loaderStore.showLoader(false);
          console.log("error in device info api", err);
          resolve(null);
        })
    })
  }

  const getProduct = (deviceRrp, fetchedProduct) => {
    return fetchedProduct.map(productItem => {
      productItem['isSubscription'] = (productItem.productId === constants.PRODUCT_IDS.PRODUCT_MONTHLY) ? true : false;
      productItem['plans'] = productItem.plans.find(planItem => planItem.devicePriceStartingRange <= deviceRrp && planItem.devicePriceEndingRange > deviceRrp);
      if (productItem['plans']) {
        productItem.plans['actualProductPrice'] = productItem.plans.actualProductPrice;
        productItem.plans['discountedProductPrice'] = productItem.plans['actualProductPrice'];
        productItem.plans['paymentSystemPrice'] = productItem.plans.paymentSystemPrice;     // 1(subscription price)
        productItem.plans['fulfillmentSystemPrice'] = productItem.plans['actualProductPrice'];   // 1(subscription price)
        if (productItem.productId === constants.PRODUCT_IDS.PRODUCT_MONTHLY) {
          productItem['billingDate'] = dateConfig.getBillingOrCoverageDate('billing');
          productItem['coverageDate'] = dateConfig.getBillingOrCoverageDate('coverage');
          productItem['promoDefinition'] = 'BOLT-100';
        } else {
          productItem['coverageDesc'] = 'Cracked Screen and Other Damage';
          productItem['promoDefinition'] = null;
        }
      }
      else {     //if device rrp doesn't match with any plan
        localStorage.setItem('isRouteFromPurchase', true);
        navigate(constants.ROUTES.DEVICE_INFO_ERROR);
      }
      return productItem;
    })
  } */

  return (
    <div className="landing-page">
      <Layout header={true} isLanding={true}>
        {/* comment out  */}
        {/* <LandingComponent productData={productArrData} deviceModel={deviceModel} deviceRrp={deviceRrp}></LandingComponent> */}
        <LandingComponent isMobile={isMobile} isTablet={isTablet}/>
        {/*<LandingFooter isMobile={isMobile} />*/}
      </Layout>
      {isMobile && <div className="pt-80"/>}
    </div>
  )
}

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;